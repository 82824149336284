import React from 'react';
import BabyRegistry from '../components/BabyRegistry';

const Registry = () => {
  return (
    <div>
      {/* Other components */}
      <BabyRegistry />
      {/* Other components */}
    </div>
  );
};

export default Registry;

