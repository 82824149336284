// components/HomePage.js

import React from 'react';
import styled from 'styled-components';
import Raiden from '../images/Raiden2.jpg'
import './HomePage.css';



const Content = styled.div`
  text-align: center;
`;


const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
`;

const Image = styled.img`
  max-width: 40%;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const HomePage = () => {
  return (
  
      <Content>
      <div className="bg">
      <div className="home-page">
        <p2>Raiden Gray</p2>
        <Image src={Raiden} alt="Raiden" />
        <Subtitle>Explore the wonderful moments of my life.</Subtitle>
        {/** Additional text or links can be added here */}
        </div>
        </div>
      </Content>
   
  );
};

export default HomePage;
