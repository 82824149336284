import React from 'react';
import './BabyRegistry.css';

const BabyRegistry = () => {
  const registryItems = [
    {
      id: 1,
      name: 'Amazon Registry',
      image: 'https://1000logos.net/wp-content/uploads/2016/10/Amazon-logo-meaning.jpg',
      description: 'Gizmos & gadgets, a bunch of them, all in one place',
      website: 'https://www.amazon.com/baby-reg/hayley-capon-october-2023-deerfieldbeach/1O0C9M5Q5QQ8E?ref_=cm_sw_r_apin_dp_RHG9DA61HG8SABTGD0S5',
    },
   
   
      {
        id: 4,
        name: 'Dresser',
        image: 'https://www.ikea.com/us/en/images/products/hemnes-8-drawer-dresser-white-stain__0627346_pe693299_s5.jpg?f=s',
        description: 'Definetly need a place to store all of my fits!',
        website: 'https://www.ikea.com/us/en/p/hemnes-8-drawer-dresser-white-stain-00318598/#content',
      },
      
      

    // Add more items as needed
  ];

  return (
    <div className="bg">
    <div className="baby-registry">
      <h1>Raiden's Registry</h1>

      <div className="fund">
              <a href="https://donate.stripe.com/fZe7uI98Xd7n4XS9AA">Donate to the Diaper Fund</a>
            </div>
      <div className="registry-items">
        {registryItems.map(item => (
          <div className="card" key={item.id}>
            <img className="item-image" src={item.image} alt={item.name} />
            <div className="item-description">
              <h2>{item.name}</h2> 
              <p>{item.description}</p>
              <a href={item.website}>Check it out</a>
            </div>
          </div>
        ))}

        


        

        
        
      </div>
      <div className="fund">
              <a href="https://donate.stripe.com/fZe7uI98Xd7n4XS9AA">Donate to the Diaper Fund</a>
            </div>
           Once anything is purchased please let us know so we can remove them! <br />
           
    </div>
    </div>
  );
};

export default BabyRegistry;
