import styled from 'styled-components';

const NavDropdown = styled.div`
  position: absolute;
  margin-top: 150px;
  background: #8da1b4;



`;

export default NavDropdown;
