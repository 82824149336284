import React from 'react';
import { useState } from 'react';
import NavDropdown from './NavDropdown';
import {
Nav,
NavLink,
NavMenu,
} from './NavbarElements';
import navBarLogo from '../../images/header.jpg'


const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = 
    useState(false); // State for dropdown menu

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <>
      <Nav>
        <NavMenu >
          <NavLink to='/' activeStyle>
            Home
          </NavLink>

          <NavLink to='/registry' activeStyle>
            Registry
          </NavLink>

		  <NavLink to='/Photos' activeStyle>
            Photos
          </NavLink>

		  <NavLink to='/Videos' activeStyle>
            Videos
          </NavLink>

       
         
        </NavMenu>
      </Nav>
    </>
  );
};

export default Navbar;


/**  <NavLink to="/events" activeStyle onClick={toggleDropdown}>
            Events
            {isDropdownOpen && (
              <NavDropdown>
                <NavLink to="/birthdays" activeStyle onClick={closeDropdown}>
                  Birthdays
                </NavLink>
                <NavLink to="/holidays" activeStyle onClick={closeDropdown}>
                  Holidays
                </NavLink>
                {/* Add more dropdown menu items /}
				</NavDropdown>
				)}
				**/ 

				
