import React from "react";






function Home() {
 
  window.location.replace('https://www.iampervasive.com/#/Raiden_Gray');

  return null;

}
export default Home
