import React from 'react';
import Videos from '../components/Videos';

const Registry = () => {
  return (
    <div>
      {/* Other components */}
      < Videos/>
      {/* Other components */}
    </div>
  );
};

export default Registry;

