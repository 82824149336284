import React from 'react';
import Home from '../components/HomePage1'

const HomePage = () => {
  return (
    <div>
      {/* Other components */}
      <Home />
      {/* Other components */}
    </div>
  );
};

export default HomePage;