import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
  background: #8da1b4;
  height: 85px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const NavLink = styled(Link)`
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 10px;
  height: 100%;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  margin-right: 10px;

  &:hover {
    color: #8facba;
  }

  &.active {
    color: #8facba;
    font-weight: bold;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  margin-left: 10px
  align-items: center;

  @media screen and (max-width: 768px) {
    height: 25px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;


