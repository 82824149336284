import React from 'react';
import Photos from '../components/Photos';

const PhotoPage = () => {
  return (
    <div>
      {/* Other components */}
      <Photos />
      {/* Other components */}
    </div>
  );
};

export default PhotoPage;