import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RegistryPage from './pages/registry';
import Home from './pages/index';
import Birthdays from './pages/birthdays';
import Events from './pages/events';
import Photos from './pages/photos';
import Videos from './pages/videos';

function App() {
return (
	<Router>
	<Navbar />
	<Routes>
	<Route path='/' element={<Home/>} exact />
		<Route path='/home' element={<Home/>} exact />	
		<Route path='/registry' element={<RegistryPage />} exact  />
		<Route path='/birthdays' element={<Birthdays />} exact  />
		<Route path='/events' element={<Events />} exact  />
		<Route path='/photos' element={<Photos />} exact  />
		<Route path='/videos' element={<Videos />} exact  />
	</Routes>
	</Router>
);
}

export default App;



